import React from "react"
import * as sectionStyles from "../../../styles/advantagessection.module.scss"
import IconBlock from "../../IconBlock/IconBlock"
import { useTranslation, Trans } from "react-i18next"

export default function AdvantagesSection() {
  const { t, i18n } = useTranslation()
  const icons = [
    {
      icon: "icon-marketing",
      heading: t("advantages.marketing"),
    },
    {
      icon: "icon-partner",
      heading: t("advantages.partner"),
    },
    {
      icon: "icon-storage",
      heading: t("advantages.storage"),
    },
    {
      icon: "icon-pricing",
      heading: t("advantages.pricing"),
    },
    {
      icon: "icon-service",
      heading: t("advantages.service.title"),
      description: t("advantages.service.description"),
    },
  ]

  return (
    <section>
      <Trans i18nKey="advantagesText">
        <h2 className={sectionStyles.title}>Переваги для партнерів</h2>

        <p className={sectionStyles.paragraph}>
          15-річний досвід роботи, а також ефективність, оперативність,
          гнучкість і прозорість є гарантією взаємодії з нашими партнерами.
          Налагоджені процеси, гнучкі умови співпраці і стабільність
          забезпечують досягнення поставлених цілей.
        </p>

        <div className={sectionStyles.icons}>
          {icons.map((item, idx) => (
            <IconBlock
              key={idx}
              icon={item.icon}
              heading={item.heading}
              description={item.description || ""}
            />
          ))}
        </div>
      </Trans>
    </section>
  )
}
