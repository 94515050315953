import React from "react"

import * as sectionStyles from "../../../styles/partnerssection.module.scss"

import CitrusLogo from "./Citrus.svg"
import ComfyLogo from "./Comfy.svg"
import FoxtrotLogo from "./Foxtrot.svg"
import MoyoLogo from "./Moyo.svg"
import RozetkaLogo from "./Rozetka.svg"

import { useTranslation, Trans } from "react-i18next"

const Partner = ({ img, alt }) => {
  return (
    <div className={sectionStyles.partner}>
      <img src={img} alt={alt} />
    </div>
  )
}

export default function PartnersSection() {
  const { t, i18n } = useTranslation()

  const partners = [
    {
      img: ComfyLogo,
      alt: "Comfy",
    },
    {
      img: FoxtrotLogo,
      alt: "Foxtrot",
    },
    {
      img: CitrusLogo,
      alt: "Citrus",
    },
    {
      img: RozetkaLogo,
      alt: "Rozetka",
    },
    {
      img: MoyoLogo,
      alt: "Moyo",
    },
  ]

  return (
    <section>
      <Trans i18nKey="partnersText">
        <h2 className={sectionStyles.title}>Наші партнери</h2>

        <p className={sectionStyles.paragraph}>
          Ми постачаємо офіційну, сертифіковану в Україні техніку. Наші фахівці
          забезпечують якісний сервіс і підтримку для партнерів, комплексні
          послуги, оперативну роботу із замовленнями. Ми йдемо на зустріч
          партнерам і готові надати оптимальні умови для взаємодії.
        </p>
      </Trans>
      <div className={sectionStyles.partners}>
        {partners.map((item, idx) => (
          <Partner img={item.img} alt={item.alt} key={idx} />
        ))}
      </div>
    </section>
  )
}
