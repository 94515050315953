import React, { useState, useEffect } from "react"

import * as sectionStyles from "../../../styles/contactformsection.module.scss"

import { useTranslation } from "react-i18next"

import emailjs from "emailjs-com"

const FormControl = ({
  id,
  type,
  component,
  label,
  placeholder,
  validation,
  value,
  handleChange,
}) => {
  return (
    <>
      {component === "input" && (
        <div className={sectionStyles.formControlInput}>
          <label htmlFor={id} className={sectionStyles.formcontrolLabel}>
            {label}
          </label>
          <input
            className={sectionStyles.formInput}
            type={type}
            id={id}
            placeholder={placeholder}
            required={validation.required}
            onChange={e => handleChange(id, e.target.value)}
            value={value}
          />
        </div>
      )}
      {component === "textarea" && (
        <div className={sectionStyles.formControlTextarea}>
          <label htmlFor={id} className={sectionStyles.formcontrolLabel}>
            {label}
          </label>
          <textarea
            id={id}
            className={sectionStyles.formTextarea}
            placeholder={placeholder}
            required={validation.required}
            onChange={e => handleChange(id, e.target.value)}
            value={value}
          ></textarea>
        </div>
      )}
    </>
  )
}

export default function ContacformSection() {
  const { t, i18n } = useTranslation()

  const [form, setForm] = useState({
    name: "",
    company: "",
    email: "",
    subject: "",
    message: "",
  })

  const formInputs = [
    {
      id: "name",
      type: "text",
      component: "input",
      label: t("contact.name.label"),
      placeholder: t("contact.name.placeholder"),
      validation: {
        required: true,
      },
    },
    {
      id: "company",
      type: "text",
      component: "input",
      label: t("contact.company.label"),
      placeholder: t("contact.company.placeholder"),
      validation: {
        required: true,
      },
    },
    {
      id: "email",
      type: "email",
      component: "input",
      label: t("contact.email.label"),
      placeholder: t("contact.email.placeholder"),
      validation: {
        required: true,
      },
    },
    {
      id: "subject",
      type: "text",
      component: "input",
      label: t("contact.subject.label"),
      placeholder: t("contact.subject.placeholder"),
      validation: {
        required: true,
      },
    },
    {
      id: "message",
      type: "textarea",
      component: "textarea",
      label: t("contact.message.label"),
      placeholder: t("contact.message.placeholder"),
      validation: {
        required: true,
      },
    },
  ]

  const handleChange = (key, value) => {
    let updated = { ...form }
    updated[key] = value
    setForm(updated)
  }

  useEffect(() => {
    handleValidation()
  }, [form])

  const [valid, setValid] = useState(false)

  const handleValidation = () => {
    let valid = true

    Object.keys(form).map(key => {
      let input = formInputs.find(x => x.id === key)

      if (
        input.validation &&
        input.validation.required &&
        form[key].length === 0
      ) {
        valid = false
      }
    })
    setValid(valid)
  }

  const handleSubmit = event => {
    event.preventDefault()

    const templateId = "template_fbebi6q"

    sendFeedback(templateId, form)
  }

  const sendFeedback = (templateId, variables) => {
    emailjs
      .send(
        "service_tgj8v4c",
        templateId,
        variables,
        "user_9xM1F6z17eS1ksEY7PCol"
      )
      .then(res => {
        alert(t("email.thankyou"))
      })
      // Handle errors here however you like, or use a React error boundary
      .catch(err =>
        console.error(
          "Oh well, you failed. Here some thoughts on the error that occured:",
          err
        )
      )
  }

  return (
    <section>
      <h2 className={sectionStyles.title}>{t("contact.title")}</h2>

      <form
        onSubmit={handleSubmit}
        className={sectionStyles.form}
        name="contact"
      >
        {formInputs.map((item, idx) => (
          <FormControl
            {...{ ...item, value: form[item.id] }}
            key={idx}
            handleChange={handleChange}
          />
        ))}
        <div className={sectionStyles.formControlActions}>
          <button
            type="submit"
            className={
              valid ? sectionStyles.validButton : sectionStyles.invalidButton
            }
          >
            <span>{t("contact.send")}</span>
          </button>
        </div>
      </form>
    </section>
  )
}
