import React from "react"

import * as sectionStyles from "../../../styles/locationsection.module.scss"

import GoogleMap from "../../GoogleMap"

import { useTranslation, Trans } from "react-i18next"

export default function LocationSection() {
  const { t, i18n } = useTranslation()

  const address = {
    lat: 50.469688,
    lng: 30.519063,
  }

  const googleMapsApiKey = "AIzaSyAehwN-KzDNw-Lk9nVWoXM9YDHL3lofEBg"

  return (
    <section>
      <Trans i18nKey="locationText">
        <h2 className={sectionStyles.title}>Де ми знаходимося</h2>
        <p className={sectionStyles.paragraph}>
          Будемо раді вам у нас у гостях за адресою:
          <br />
          Вул. Почайнинська, 28 (1 поверх), м. Київ
        </p>
      </Trans>
      <div className={sectionStyles.gmap}>
        <GoogleMap center={address} googleMapsApiKey={googleMapsApiKey} />
      </div>
    </section>
  )
}
