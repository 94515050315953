import React from "react"
import GoogleMapReact from "google-map-react"
import { useTranslation } from "react-i18next"

const isClient = typeof window !== "undefined"

export default function GoogleMap(props) {
  const { t, i18n } = useTranslation()

  const { center, googleMapsApiKey } = props

  const handleApiLoaded = (map, maps) => {
    new maps.Marker({
      position: center,
      map,
      title: t("map.address"),
    })
  }

  return (
    <>
      {isClient && (
        <GoogleMapReact
          bootstrapURLKeys={{ key: googleMapsApiKey, language: i18n.language }}
          defaultCenter={center}
          defaultZoom={14}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        />
      )}
    </>
  )
}
