import React from "react"
import * as footerStyles from "../../styles/footer.module.scss"

import { useTranslation } from "react-i18next"

import FBIcon from "./FBIcon.svg"
import IGIcon from "./IGIcon.svg"

const Social = props => {
  return (
    <a
      className={footerStyles.socialnetwork}
      href={props.link}
      target="_blank"
      rel="noreferrer"
    >
      <img src={props.img} alt={props.name} />
    </a>
  )
}

export default function Footer() {
  const { t, i18n } = useTranslation()

  const socials = [
    {
      img: FBIcon,
      name: "Facebook",
      link: "https://www.facebook.com/wacom.ua",
    },
    {
      img: IGIcon,
      name: "Instagram",
      link: "https://www.instagram.com/wacomukraine/",
    },
  ]
  return (
    <footer className={footerStyles.footer}>
      <div className={footerStyles.copyright}>
        {`© ${new Date().getFullYear()} ${t("footer.copyright")}`}
      </div>
      <div className={footerStyles.socials}>
        {socials.map((x, idx) => (
          <Social {...x} key={idx} />
        ))}
      </div>
    </footer>
  )
}
