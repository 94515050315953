import React from "react"

import * as sectionStyles from "../../../styles/introsection.module.scss"

import IconBlock from "../../IconBlock/IconBlock"

import { useTranslation, Trans } from "react-i18next"

export default function IntroSection() {
  const { t, i18n } = useTranslation()

  return (
    <section>
      <Trans i18nKey="introText">
        <h1 className={sectionStyles.title}>
          15 років успішної роботи в дистріб’юції IT-продуктів
        </h1>
        <p className={sectionStyles.paragraph}>
          «Компанія Новелла» — національний дистриб'ютор продукції в сфері
          інформаційних технологій, орієнтується на підтримку стандартів якості
          в обслуговуванні партнерів, пропонує вигідні економічні умови,
          забезпечує оперативну маркетингову і технічну підтримку. Торговий
          портфель компанії включає товари визнаних світових виробників
          IT-індустрії. Флагманський бренд — Wacom.
        </p>
        <p className={sectionStyles.paragraph}>
          Wacom — світовий лідер з виробництва графічних планшетів. Лінійка
          продуктів Wacom представлена технікою на будь-який смак, від
          початківців до професіоналів в області фото, дизайну і графіки. Wacom
          постійно модернізує та створює нові технології для роботи з цифровою
          графікою.
        </p>

        <div className={sectionStyles.icons}>
          <IconBlock
            icon="wacom-logo"
            heading={t("intro.gotowacom")}
            description="wacom.kiev.ua"
            to="https://wacom.kiev.ua"
            style={{ maxWidth: "250px" }}
          />
        </div>
      </Trans>
    </section>
  )
}
