import React from "react"

import * as dividerStyles from "../../styles/divider.module.scss"
import BackgroundImage1 from "./style-1.svg"
import BackgroundImage2 from "./style-2.svg"
import BackgroundImage3 from "./style-3.svg"
import BackgroundImage4 from "./style-4.svg"

export default function Divider({ type }) {
    const backgrounds = [
        "",
        BackgroundImage1,
        BackgroundImage2,
        BackgroundImage3,
        BackgroundImage4
    ]

    const background = backgrounds[type]

    return (
        <div
            className={dividerStyles.divider}
            style={{ backgroundImage: `url(${background})` }}
        ></div>
    )
}
