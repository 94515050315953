import React, { useState } from "react"
import * as headerStyles from "../../styles/header.module.scss"
import Logo from "./Logo.svg"
import HeaderImage from "./Image.jpg"

import LanguageSwitcher from '../LanguageSwitcher'

export default function Header() {
    return (
        <header
            className={headerStyles.header}
            style={{ backgroundImage: `url(${HeaderImage})` }}
        >
            <div className={headerStyles.container}>
                <LanguageSwitcher />
                <img
                    src={Logo}
                    className={headerStyles.logoImg}
                    alt="Novella logo"
                />
            </div>
        </header>
    )
}
