import React from "react"

import Header from "../components/Header"
import IntroSection from "../components/sections/IntroSection"
import Divider from "../components/Divider/Divider"
import AdvantagesSection from "../components/sections/AdvantagesSection"
import PartnersSection from "../components/sections/PartnersSection"
import ContacformSection from "../components/sections/ContacformSection"
import LocationSection from "../components/sections/LocationSection"
import Footer from "../components/Footer/Footer"
import SEO from "../components/SEO"

import '../i18n/config';

export default function Home() {
    return (
        <>
            <SEO />
            <Header />
            <IntroSection />
            <Divider type={1} />
            <AdvantagesSection />
            <Divider type={2} />
            <PartnersSection />
            <Divider type={3} />
            <ContacformSection />
            <Divider type={4} />
            <LocationSection />
            <Footer />
        </>
    )
}
