import React from "react"

import * as blockStyles from "../../styles/iconblock.module.scss"

import wacomLogo from "./wacom-logo.svg"

import iconMarketing from "./Icon-marketing.svg"
import iconPartner from "./Icon-partner.svg"
import iconPricing from "./Icon-pricing.svg"
import iconService from "./Icon-service.svg"
import iconStorage from "./Icon-storage.svg"

const icons = [
  {
    name: "wacom-logo",
    icon: wacomLogo,
    alt: "Wacom",
  },
  {
    name: "icon-marketing",
    icon: iconMarketing,
    alt: "Маркетинг",
  },
  {
    name: "icon-partner",
    icon: iconPartner,
    alt: "Партнерство",
  },
  {
    name: "icon-pricing",
    icon: iconPricing,
    alt: "Цінова політика",
  },
  {
    name: "icon-service",
    icon: iconService,
    alt: "Серіс",
  },
  {
    name: "icon-storage",
    icon: iconStorage,
    alt: "Склад",
  },
]

const Block = ({ icon, heading, description, to, style }) => {
  const choosenOne = icons.find(x => x.name === icon) || ""

  return (
    <>
      <div className={blockStyles.icon}>
        <img src={choosenOne.icon} alt={choosenOne.alt} />
      </div>
      <div className={blockStyles.content} style={style}>
        <div className={blockStyles.heading}>{heading}</div>
        {description && (
          <div className={blockStyles.description}>{description}</div>
        )}
      </div>
    </>
  )
}

export default function IconBlock(props) {
  return (
    <div className={blockStyles.block}>
      {typeof props.to !== "undefined" && (
        <a
          href={props.to}
          className={blockStyles.link}
          target="_blank"
          rel="noreferrer"
        >
          <Block {...props} />
        </a>
      )}

      {typeof props.to === "undefined" && <Block {...props} />}
    </div>
  )
}
