// extracted by mini-css-extract-plugin
export const title = "contactformsection-module--title--jvkVG";
export const form = "contactformsection-module--form--1S-9b";
export const formControlInput = "contactformsection-module--formControlInput--2P8fZ";
export const formControlTextarea = "contactformsection-module--formControlTextarea--1KtkB";
export const formControlActions = "contactformsection-module--formControlActions--2336t";
export const formcontrolLabel = "contactformsection-module--formcontrolLabel--12D5y";
export const formInput = "contactformsection-module--formInput--1m2EN";
export const formTextarea = "contactformsection-module--formTextarea--3Cq2M";
export const validButton = "contactformsection-module--validButton--_JOIj";
export const invalidButton = "contactformsection-module--invalidButton--1z1br";
export const span = "contactformsection-module--span--3wky5";