import React, { useState, useEffect } from "react"
import * as switcherStyles from "../../styles/langswitcher.module.scss"
import { useTranslation } from "react-i18next"

export default function LanguageSwitcher() {
	const { t, i18n } = useTranslation()

	const switchLang = () => {
		i18n.changeLanguage(i18n.language === "uk-UA" ? "en" : "uk-UA")
	}

	return (
		<>
			<button className={switcherStyles.switchLang} onClick={switchLang}>
				{i18n.language === "uk-UA" ? "UA" : "EN"}
			</button>
		</>
	)
}
